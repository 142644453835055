import { useState } from "react";
import { TbArrowRight } from "react-icons/tb";
import { BeatLoader } from "react-spinners";
import dayjs from "dayjs";
import { TablePagination } from "@mui/material";
import Typography from "../../../../base-components/Typography";
import { useWindowSize } from "../../../../hooks/windowSize";
import Date from "../../../../components/DatePicker";

const tempData = [
  {
    name: "Бүтээгдэхүүн 1",
    income: "500,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 2",
    bank: "Голомт банк",
    investment: "300,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 3",
    income: "200,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 4",
    income: "500,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 5",
    income: "300,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 6",
    income: "200,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 7",
    income: "500,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 8",
    income: "300,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 9",
    income: "200,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 10",
    income: "500,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 11",
    income: "300,000",
    interest: 5,
    date: "2024-12-25",
  },
  {
    name: "Бүтээгдэхүүн 12",
    income: "200,000",
    interest: 5,
    date: "2024-12-25",
  },
];

const Statement = () => {
  const { height } = useWindowSize();

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const detailLoading = false;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="relative shadow-card p-5 rounded-base grow">
      <div className="grid lg:flex items-center justify-between mb-6">
        <Typography className="text-xl font-normal">Хуулга</Typography>
        <div className="flex items-center lg:space-x-2">
          <Date
            label="эхлэх огноо"
            date={startDate}
            onChange={(date) => setStartDate(date)}
            className="border border-primary rounded-base px-2 p-0.5"
            view="month"
          />
          <TbArrowRight size={25} className="mt-3" />
          <Date
            label="дуусах огноо"
            date={endDate}
            onChange={(date) => setEndDate(date)}
            minDate={dayjs(startDate)?.add(1, "day")}
            className="border border-primary rounded-base px-2 p-0.5"
            view="month"
          />
        </div>
      </div>
      <div style={{ height: height - 308 }} className="overflow-auto w-full">
        <table className="w-full">
          <thead className="bg-card-base rounded-[5px] sticky top-0 text-font-third">
            <tr>
              <th className="py-2.5 px-2 w-40">
                <Typography color="text-font" className="font-normal">
                  Хуримтлалын нэр
                </Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Орлого
                </Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Бодогдох хүү
                </Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Огноо
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {tempData?.length > 0 &&
              tempData.map((order) => (
                <tr key={order.id}>
                  <td className="py-2.5 px-2 whitespace-nowrap">
                    <Typography>{order?.name}</Typography>
                  </td>
                  <td className="py-2.5 px-2 whitespace-nowrap">
                    <Typography>{order?.income ?? "0"}₮</Typography>
                  </td>
                  <td className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="text-center">
                      {order?.interest}%
                    </Typography>
                  </td>
                  <td className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="text-center">
                      {order?.date}
                    </Typography>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {detailLoading ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50">
            <BeatLoader size={13} />
          </div>
        ) : (
          tempData?.length === 0 && (
            <div className="flex justify-center w-full mt-20 text-font font-normal">
              Хуулга байхгүй байна
            </div>
          )
        )}
      </div>
      <TablePagination
        component="div"
        count={10}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className=""
      />
    </div>
  );
};

export default Statement;
