import Service from "./BaseService";

const accessHistory = (config) => Service.get(`/api/v1/access/history`, { ...config, nonLoading: true }, 8080);
const registeredDevices = (config) => Service.get(`/api/v1/device/list`, { ...config, nonLoading: true }, 8080);

const infoDetail = (config) => Service.get(`/api/v1/info/detail`, { ...config, nonLoading: true }, 8080);
const changePassword = (data, config) => Service.post(`/api/v1/password/change`, data, { ...config, nonLoading: true }, 8080);
const total = (data, config) => Service.get(`/api/v1/order/balance/total`, data, { ...config, nonLoading: true }, 8080);
const news = (data, config) => Service.get(`/api/v1/news/list`, data, { ...config, nonLoading: true }, 8080);


const changePhoneNumberOtp = (phoneNumber, config) => Service.post(`/api/v1/phoneNumber/change?phoneNumber=${phoneNumber}`, {}, { ...config, nonLoading: true }, 8080);
const changePhoneNumberVerify = ({ phoneNumber, otp }, config) => Service.post(`/api/v1/phoneNumber/change/otp?phoneNumber=${phoneNumber}&otp=${otp}`, {}, { ...config, nonLoading: true }, 8080);

const changeEmailOtp = (email, config) => Service.post(`/api/v1/send/email?email=${email}`, {}, { ...config, nonLoading: true }, 8080);
const changeEmailVerify = ({ email, optValue }, config) => Service.post(`/api/v1/confirm/email?email=${email}&optValue=${optValue}`, {}, { ...config, nonLoading: true }, 8080);

const ProfileService = {
    accessHistory,
    registeredDevices,
    infoDetail,
    changePassword,
    total,
    news,
    changePhoneNumberOtp,
    changePhoneNumberVerify,
    changeEmailOtp,
    changeEmailVerify

}

export default ProfileService;