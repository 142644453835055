import http from "./HttpClient";
import { useStore } from "../stores";
import { toast } from "react-toastify";
import { tokenKey } from "../utils/constants";

http.interceptors.request.use((config) => {
  const paramConfig = { ...config };
  const token = localStorage.getItem(tokenKey);
  if (token)
    paramConfig.headers.Authorization = `Bearer ${token}`;


  return paramConfig;
});

function interceptError(error, url) {
  let message = "";
  if (error?.response?.status === 401 && url !== "/auth/login") {
    // Handle 401 error: unauthorized
    localStorage.removeItem(tokenKey); // Clear token
    useStore.setState({ logged: false }); // Update your application state if needed
    toast.error("Таны нэвтрэх эрх дууссан байна. Дахин нэвтэрнэ үү.")
    setTimeout(() => {
      window.location.href = "/login"; // Redirect to the login page
    }, 1000)

  }

  else if (error?.response?.data?.message) {
    message = error?.response?.data?.message;
  } else if (error?.response?.data?.value) {
    message = error?.response?.data?.value;
  } else {
    message = "Сервертэй холбогдоход алдаа гарлаа";
  }
  console.log("error?.response?.data?",error?.response?.data);

  if (error?.response?.data?.responseMsg) {
    message = error?.response?.data?.responseMsg;
  } else {
    message = "Сервертэй холбогдоход алдаа гарлаа";
  }
  console.log("urlurlurl", url, url == "/api/v1/info/detail");
  if (url == "/api/v1/info/detail") {
    message = "";
  } else {
    toast.error(message);
    return message;
  }

}

const get = (url, config, port = 8022) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  console.log(
    "garch bui url",
    `${process.env.REACT_APP_API_URL}:${port}${url}`
  ); console.log(
    "garch bui url123123", url)

  let baseUrl = process.env.REACT_APP_API_URL;
  if (port == 8022) {
    baseUrl = process.env.REACT_APP_API_AUTH_URL
  }
  return http
    .get(`${baseUrl}${url}`, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      console.log("error ni yu baina", err);
      return Promise.reject(interceptError(err, url));
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const post = (url, payload, config, port = 8022) => {
  if (!config?.nonLoading) useStore.setState({ loading: true });
  console.log(
    "garch bui url",
    `${process.env.REACT_APP_API_URL}:${port}${url}`
  ); console.log(
    "garch bui url123123", url)

  let baseUrl = process.env.REACT_APP_API_URL;
  if (port == 8022) {
    baseUrl = process.env.REACT_APP_API_AUTH_URL
  }
  return http
    .post(`${baseUrl}${url}`, payload, config)
    .then((response) => Promise.resolve(response.data))
    .catch((err) => {
      console.log("error ni yu baina", err);
      console.error("Response error:", err?.response?.data);
      console.error("Status code:", err?.response?.status);
      return Promise.reject(interceptError(err, url));
    })
    .finally(() => {
      if (!config?.nonLoading) useStore.setState({ loading: false });
    });
};

const BaseService = {
  get,
  post,
};

export default BaseService;
