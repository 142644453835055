import { useEffect, useState } from "react";
import { TbArrowRight } from "react-icons/tb";
import { TablePagination } from "@mui/material";
import dayjs from "dayjs";
import { dispatch, useSelector } from "../../redux/store";
import {
  changeEndDate,
  changeStartDate,
  getOrderDetailThunk,
} from "../../redux/slices/savings";
import { useWindowSize } from "../../hooks/windowSize";
import Typography from "../../base-components/Typography";
import MoneyAddModal from "./money_add_modal";
import Date from "../../components/DatePicker";
import { BeatLoader } from "react-spinners";

const tempData = [
  {
    productName: "Бүтээгдэхүүн 1",
    debit: "500000",
    calculatedInterest: 5,
    createdDate: "2024-12-25",
  },
  {
    productName: "Бүтээгдэхүүн бүтээгдэхүүн",
    debit: "200000",
    calculatedInterest: 3,
    createdDate: "2024-12-25",
  },
  {
    productName: "Бүтээгдэхүүн 2",
    debit: "300000",
    calculatedInterest: 5,
    createdDate: "2024-12-25",
  },
];

const Statement = () => {
  const {
    detailLoading,
    selectedOrder,
    selectOrderId,
    moneyAddModal,
    orders,
    startDate,
    endDate,
  } = useSelector((state) => state.savings);
  const { height } = useWindowSize();

  // const [startDate, setStartDate] = useState(dayjs().month(dayjs().month() - 1));
  // const [endDate, setEndDate] = useState(dayjs());

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    console.log("selectOrderId : ", selectedOrder);
    if (selectOrderId) {
      const order = orders.find((e) => e.id == selectOrderId);
      console.log("orderorderorder : ", order.dayOfMonth);
      startDate.date(order.dayOfMonth);
      endDate.date(order.dayOfMonth);
      console.log(
        "orderorderorderDD/MM/YYYY : ",
        startDate.date(order.dayOfMonth).format("YYYY-MM-DD")
      );
      console.log(
        "orderorderorderDD/MM/YYYY : ",
        endDate.date(order.dayOfMonth).format("YYYY-MM-DD")
      );
      dispatch(
        getOrderDetailThunk(
          selectOrderId,
          startDate.date(order.dayOfMonth).format("YYYY-MM-DD"),
          endDate.date(order.dayOfMonth).format("YYYY-MM-DD")
        )
      );
    }
  }, [selectOrderId, startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="relative sm:shadow-card p-5 rounded-base bg-white">
      <div className="grid lg:flex items-center justify-between mb-6">
        <Typography className="hidden sm:block text-xl font-normal">
          Хуулга
        </Typography>
        <div className="flex items-center space-x-2">
          <Date
            label="эхлэх огноо"
            date={startDate}
            onChange={(date) => {
              dispatch(changeStartDate(date));
            }}
            className="border border-primary rounded-base px-2 p-0.5"
            view="month"
          />
          <TbArrowRight size={25} className="mt-3" />
          <Date
            label="дуусах огноо"
            date={endDate}
            onChange={(date) => {
              dispatch(changeEndDate(date));
            }}
            minDate={dayjs(startDate)?.add(1, "month")}
            className="border border-primary rounded-base px-2 p-0.5"
            view="month"
          />
        </div>
      </div>
      <div
        style={{ height: height - 284 }}
        className="overflow-auto w-full relative"
      >
        <table className="w-full">
          <thead className="bg-card-base rounded-[5px] sticky top-0 text-font-third">
            <tr>
              <th className="py-2.5 px-2 w-40">
                <Typography className="font-normal">Хуримтлалын нэр</Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography className="font-normal">Орлого</Typography>
              </th>
              <th className="py-2.5 px-2 w-36">
                <Typography className="font-normal">Бодогдох хүү</Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography className="font-normal">Огноо</Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedOrder?.length > 0 &&
              selectedOrder.map((order) => (
                <tr key={order.id}>
                  <th className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="font-normal">
                      {order?.productName}
                    </Typography>
                  </th>
                  <th className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="font-normal">
                      {order?.debit.toLocaleString()}₮
                    </Typography>
                  </th>
                  <th className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="font-normal">
                      {order?.calculatedInterest}%
                    </Typography>
                  </th>
                  <th className="py-2.5 px-2 whitespace-nowrap">
                    <Typography className="font-normal">
                      {order?.createdDate}
                    </Typography>
                  </th>
                </tr>
              ))}
          </tbody>
        </table>

        {detailLoading ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50">
            <BeatLoader size={13} />
          </div>
        ) : (
          selectedOrder?.length === 0 && (
            <div className="flex justify-center w-full mt-20 text-font font-normal">
              Хуулга байхгүй байна
            </div>
          )
        )}
        <TablePagination
          component="div"
          count={10}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="absolute right-4 bottom-0"
        />
      </div>
      {moneyAddModal && <MoneyAddModal></MoneyAddModal>}
    </div>
  );
};

export default Statement;
