import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "../../hooks/windowSize";
import Pdf from "../../components/Pdf";

const MyContracts = () => {
  const { height } = useWindowSize();

  const pdfs = [
    `${process.env.PUBLIC_URL}/geree.pdf`,
    `${process.env.PUBLIC_URL}/geree.pdf`,
    `${process.env.PUBLIC_URL}/geree.pdf`,
    `${process.env.PUBLIC_URL}/geree.pdf`,
  ];

  const [currentIdx, setCurrentIdx] = useState(0);

  return (
    <div className="overflow-hidden">
      <div
        className="flex transition ease-out duration-300"
        style={{
          transform: `translateX(${currentIdx * 100}%)`,
        }}
      >
        {pdfs?.length > 0 &&
          pdfs.map((pdf, idx) => (
            <React.Fragment key={idx}>
              <Pdf url={pdf} height={height - 200} title="Хуримтлалын гэрээ" />
            </React.Fragment>
          ))}
      </div>
      <div className="absolute bottom-2 w-full flex justify-center gap-3">
        {pdfs?.length > 0 &&
          pdfs?.map((_, idx) => (
            <button
              onClick={() => setCurrentIdx(idx)}
              className={twMerge([
                "rounded-full w-4 h-4 bg-black/80",
                idx === currentIdx && "bg-gray-400",
              ])}
            ></button>
          ))}
      </div>
    </div>
  );
};

export default MyContracts;
