import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Bell } from "../../assets/icon/bell.svg";
import { formatDate } from "../../utils/fomatter";

const NotificationPopover = ({
  onSelect,
  notifications,
  modalVisible,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState(10);
  const popoverRef = useRef(null);

  const togglePopover = () => setIsOpen(!isOpen);

  const markAsRead = (notification) => {
    onSelect(notification);
  };

  const loadMoreNotifications = () => {
    setVisibleNotifications((prev) => prev + 10);
  };

  useEffect(() => {
    if (!modalVisible) {
      const handleClickOutside = (event) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [modalVisible]);

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={togglePopover}
        className="flex items-center justify-center h-10 w-10 bg-white border border-gray-200 rounded-full shadow focus:outline-none"
      >
        <Bell strokeWidth={0.8} stroke="currentColor" className="h-5 w-5" />
        {notifications.filter((n) => !n.isRead).length == 0 ? (
          <></>
        ) : (
          <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
            {notifications.filter((n) => !n.isRead).length}
          </span>
        )}
      </button>

      {isOpen && (
        <div
          ref={popoverRef}
          className="absolute right-0 mt-2 w-72 sm:w-80 md:w-96 max-h-[calc(100vh-5rem)] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10"
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {loading ? (
              <p className="px-6 py-4 text-sm text-gray-700">Уншиж байна...</p>
            ) : notifications.length === 0 ? (
              <p className="px-6 py-4 text-sm text-gray-700">
                Мэдэгдэл байхгүй байна
              </p>
            ) : (
              notifications.slice(0, visibleNotifications).map(
                (notification) => (
                  console.log("notification", notification),
                  (
                    <div
                      key={notification.id}
                      className={`flex justify-between items-center px-6 py-4 text-sm ${
                        notification.isRead ? "bg-gray-100" : "bg-white"
                      } hover:bg-gray-200 cursor-pointer`}
                      onClick={() => markAsRead(notification)}
                    >
                      <div className="flex items-center">
                        <div className="mr-4">
                          <Bell
                            stroke={
                              notification.isRead ? "primary" : "currentColor"
                            }
                            className="h-5 w-5"
                          />
                        </div>
                        <div>
                          <span
                            className={
                              notification.isRead
                                ? "text-gray-600"
                                : "text-black"
                            }
                          >
                            {notification.title}
                          </span>
                          <div className="text-xs text-gray-500">
                            {formatDate(notification.createdDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              )
            )}
            {visibleNotifications < notifications.length && (
              <div className="border-t mt-2">
                <button
                  onClick={loadMoreNotifications}
                  className="block w-full text-center px-4 py-2 text-sm text-blue-500 hover:text-blue-700"
                >
                  Цааш
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationPopover;
