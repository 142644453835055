import { useEffect } from "react";
import { dispatch } from "../../redux/store";
import { getUserInfo } from "../../redux/slices/profile";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default Dashboard;
