import Typography from "../../base-components/Typography";
import { Outlet } from "react-router-dom";
import { useIsLg, useIsSm } from "../../hooks/mediaQuery";
import { useEffect } from "react";
import AppDownloadPopup from "./app_download";

const Auth = () => {
  const isLg = useIsLg();
  const isSm = useIsSm();
  useEffect(() => {

  }, [])
  return (
    <div className="fixed inset-0 py-8 background grid lg:grid-cols-2 place-items-center text-primary select-none max-xs:px-8">
      <div className="xl:w-[506px] lg:w-[420px] xs:w-96 w-full text-center">
        <Typography
          variant={isSm ? "Display" : "Headline"}
          size={isLg ? "md" : isSm ? "sm" : "lg"}
        >
          "Гэрэгэ Хуримтлал"
        </Typography>
        <Typography
          variant={isLg ? "Display" : "Headline"}
          size={isLg ? "sm" : isSm ? "md" : "lg"}
          className="font-normal"
        >
          хэрэглэгчийн системд тавтай морил
        </Typography>
      </div>
      <div className="rounded-2xl bg-white p-8 max-xs:w-full mt-4 border-stroke-dark-12 border drop-shadow-lg">
        <Outlet />
      </div>
      <AppDownloadPopup></AppDownloadPopup>
    </div>
  );
};

export default Auth;
