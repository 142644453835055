import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProfileService from "../../services/ProfileService";
import DanService from "../../services/DDanService";
// ----------------------------------------------------------------------

const initialState = {
  accessDeviceLoading: false,
  accessDevices: [],
  error: null,
  success: false,
  registerDeviceLoading: false,
  registerDevices: [],
  danLoading: true,
  danSuccess: false,
  user: null,
  registerAddLoading: false,
  registerAddSuccess: false,
  danReponse: null,
  danCheckLoading: false,
  danConnected: false,
  danCheckSuccess: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccess: false,

  //total
  totalLoading: false,
  totalError: null,
  total: null,

  //news
  news: [],

  //change phoner number 
  changePhoneNumberLoading: false,
  changePhoneNumberSuccess: false,
  changePhoneNumberError: false,

  changePhoneNumberOtpLoading: false,
  changePhoneNumberOtpSuccess: false,
  changePhoneNumberOtpError: false,


  //change email 
  changeEmailLoading: false,
  changeEmailSuccess: false,
  changeEmailError: false,

  changeEmailOtpLoading: false,
  changeEmailOtpSuccess: false,
  changeEmailOtpError: false,

};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    startAccessDeviceLoading(state) {
      state.accessDeviceLoading = true;
    },
    stopAccessDeviceLoading(state) {
      state.accessDeviceLoading = false;
    },
    startDanLoading(state) {
      state.danLoading = true;
    },
    stopDanLoading(state) {
      state.danLoading = false;
    },
    startRegisterDeviceLoading(state) {
      state.registerDeviceLoading = true;
    },
    stopRegisterDeviceLoading(state) {
      state.registerDeviceLoading = false;
    },
    hasError(state, action) {
      state.accessDeviceLoading = false;
      state.registerDeviceLoading = false;
      state.error = action.payload;
    },
    danError(state, action) {
      state.danLoading = false;
      state.danSuccess = true;
      state.error = action.payload;
    },

    accessDeviceSuccess(state, action) {
      state.accessDeviceLoading = false;
      state.accessDevices = action.payload;
    },
    registerDeviceSuccess(state, action) {
      state.registerDeviceLoading = false;
      state.registerDevices = action.payload;
    },
    initialStateProfile(state) {
      state.accessDeviceLoading = false;
      state.error = null;
      state.success = false;
    },
    hideError(state) {
      state.error = null;
    },

    // stopDanLoading(state) {
    //     state.danLoading = false;
    // },
    // startDanLoading(state) {
    //     state.danLoading = true;
    // },
    danSuccess(state) {
      state.danLoading = false;
      state.danSuccess = true;
    },
    changeDanSuccess(state) {
      state.danLoading = false;
      state.danSuccess = false;
    },
    changeDanCheckSuccess(state) {
      state.danCheckLoading = false;
      state.danCheckSuccess = false;
    },
    userSuccess(state, action) {
      state.user = action.payload;
    },
    addRegisterLoading(state) {
      state.registerAddLoading = true;
    },
    addRegisterError(state, action) {
      state.registerAddLoading = false;
      state.error = action.payload;
    },
    addRegisterSuccess(state, action) {
      state.registerAddLoading = false;
      state.registerAddSuccess = true;
      state.danReponse = action.payload;
    },

    changeRegisterAddSuccess(state) {
      state.registerAddLoading = false;
      state.registerAddSuccess = false;
    },

    danCheckError(state, action) {
      state.danCheckLoading = false;
      state.error = action.payload;
    },
    danCheckSuccess(state, action) {
      state.danCheckLoading = false;
      state.danCheckSuccess = true;
      state.danConnected = action.payload;
    },



    startChangePasswordLoading(state) {
      state.changePasswordLoading = true;
    },
    stopChangePasswordLoading(state) {
      state.changePasswordLoading = false;
    },
    changedPasswordSuccess(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = null;
      state.changePasswordSuccess = false;

    },
    passwordSuccess(state) {
      state.changePasswordLoading = false;
      state.changePasswordError = null;
      state.changePasswordSuccess = true;
    },
    passwordError(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
      state.changePasswordSuccess = false;
    },

    startTotalLoading(state) {
      state.totalLoading = true;
    },
    stopTotalLoading(state) {
      state.totalLoading = false;
    },
    totalSuccess(state, action) {
      state.total = action.payload;
      state.totalError = null;

    },

    totalError(state, action) {
      state.totalLoading = false;
      state.totalError = action.payload;
    },

    newsSuccess(state, action) {
      state.news = action.payload;
    },

    //changePhonenumber loading
    startChangeNumberloading(state) {
      state.changePhoneNumberLoading = true;
      state.changePhoneNumberError = null;
    },
    changePhoneNumberSuccess(state) {
      state.changePhoneNumberLoading = false;
      state.changePhoneNumberError = null;
      state.changePhoneNumberSuccess = true;
    },
    changePhoneNumberError(state, action) {
      state.changePhoneNumberLoading = false;
      state.changePhoneNumberError = action.payload;
      state.changePhoneNumberSuccess = false;
    },

    changeSuccessChangePhoneNumber(state) {
      state.changePhoneNumberLoading = false;
      state.changePhoneNumberSuccess = false;
    },

    startChangeNumberOtploading(state) {
      state.changePhoneNumberOtpLoading = true;
      state.changePhoneNumberOtpError = null;
    },
    changePhoneNumberOtpSuccess(state) {
      state.changePhoneNumberOtpLoading = false;
      state.changePhoneNumberOtpError = null;
      state.changePhoneNumberOtpSuccess = true;
    },
    changePhoneNumberOtpError(state, action) {
      state.changePhoneNumberOtpLoading = false;
      state.changePhoneNumberOtpError = action.payload;
      state.changePhoneNumberOtpSuccess = false;
    },

    changeSuccessChangePhoneNumberOtp(state) {
      state.changePhoneNumberOtpLoading = false;
      state.changePhoneNumberOtpSuccess = false;
    },

    //changeEmail loading
    startEmailloading(state) {
      state.changeEmailLoading = true;
      state.changeEmailError = null;
    },
    changeEmailSuccess(state) {
      state.changeEmailLoading = false;
      state.changeEmailError = null;
      state.changeEmailSuccess = true;
    },
    changeEmailError(state, action) {
      state.changeEmailLoading = false;
      state.changeEmailError = action.payload;
      state.changeEmailSuccess = false;
    },

    changeSuccessEmail(state) {
      state.changeEmailLoading = false;
      state.changeEmailSuccess = false;
    },

    startChangeEmailOtploading(state) {
      state.changeEmailOtpLoading = true;
      state.changeEmailOtpError = null;
    },
    changeEmailOtpSuccess(state) {
      state.changeEmailOtpLoading = false;
      state.changeEmailOtpError = null;
      state.changeEmailOtpSuccess = true;
    },
    changeEmailOtpError(state, action) {
      state.changeEmailLoading = false;
      state.changeEmailOtpError = action.payload;
      state.changeEmailOtpSuccess = false;
    },

    changeSuccessChangeEmailOtp(state) {
      state.changeEmailOtpLoading = false;
      state.changeEmailOtpSuccess = false;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startAccessDeviceLoading,
  stopAccessDeviceLoading,
  hideError,
  initialStateProfile,
  changeDanSuccess,
  changeRegisterAddSuccess,
  changeDanCheckSuccess,
  changedPasswordSuccess,
  changeSuccessChangePhoneNumber,
  changeSuccessChangePhoneNumberOtp,
  changeSuccessEmail,
  changeSuccessChangeEmailOtp
} = slice.actions;

// // ----------------------------------------------------------------------

export function accessHistoryThunk() {
  return async () => {
    dispatch(slice.actions.startAccessDeviceLoading());
    try {
      const response = await ProfileService.accessHistory();
      dispatch(slice.actions.accessDeviceSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}

export function registeredDevicesThunk() {
  return async () => {
    dispatch(slice.actions.startRegisterDeviceLoading());
    try {
      const response = await ProfileService.registeredDevices();
      dispatch(slice.actions.registerDeviceSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  console.log("Code:", code);
  console.log("State:", state);
  return `code=${code}&state=${state}`;
}

export function danConnectThunk() {
  return async () => {
    console.log("getQueryParams();", getQueryParams());
    dispatch(slice.actions.startDanLoading());
    try {
      await DanService.connectDan(getQueryParams());
      dispatch(slice.actions.danSuccess());
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}

export function getUserInfo() {
  return async () => {
    // dispatch(slice.actions.startDanLoading());
    try {
      var response = await ProfileService.infoDetail();
      console.log("responseresponse", response);
      dispatch(slice.actions.userSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      // dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function danRegisterAdd(body) {
  return async () => {
    dispatch(slice.actions.addRegisterLoading(body));
    try {
      const response = await DanService.checkRegisterDan(body);
      dispatch(slice.actions.addRegisterSuccess(response));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.addRegisterError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function danCheckThunk() {
  return async () => {
    dispatch(slice.actions.startDanLoading());
    try {
      const response = await DanService.danCheck();
      dispatch(slice.actions.danCheckSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danCheckError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function changePasswordThunk(body) {
  return async () => {
    dispatch(slice.actions.startChangeNumberOtploading());
    try {
      const response = await ProfileService.changePhoneNumberVerify(body);
      dispatch(slice.actions.passwordSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.changePass(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}


export function totalThunk() {
  return async () => {
    dispatch(slice.actions.startTotalLoading());
    try {
      const response = await ProfileService.total();
      dispatch(slice.actions.totalSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.totalError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };


}

export function newsThunk() {
  return async () => {
    console.log("getQueryParams();", getQueryParams());
    // dispatch(slice.actions.startDanLoading());
    try {
      const res = await ProfileService.news();
      dispatch(slice.actions.newsSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}

export function changePhoneNumberThunk(phoneNumber) {
  return async () => {
    dispatch(slice.actions.startChangePasswordLoading());
    try {
      const res = await ProfileService.changePhoneNumberOtp(phoneNumber);
      dispatch(slice.actions.changePhoneNumberSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.changePhoneNumberError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function changePhoneNumberVerifyThunk(body) {
  return async () => {
    dispatch(slice.actions.startChangePasswordLoading());
    try {
      const res = await ProfileService.changePhoneNumberVerify(body);
      dispatch(slice.actions.changePhoneNumberOtpSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.changePhoneNumberOtpError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  }
};



//email
export function changeEmailThunk(email) {
  return async () => {
    dispatch(slice.actions.startChangePasswordLoading());
    try {
      const res = await ProfileService.changeEmailOtp(email);
      dispatch(slice.actions.changeEmailSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.changeEmailError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function changeEmailVerifyThunk(body) {
  return async () => {
    dispatch(slice.actions.startChangeEmailOtploading());
    try {
      const res = await ProfileService.changeEmailVerify(body);
      dispatch(slice.actions.changeEmailOtpSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.changeEmailOtpError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}