import { Viewer, Worker } from "@react-pdf-viewer/core";

import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";

import { IconButton } from "@mui/material";
import {
  LuChevronLeft,
  LuChevronRight,
  LuExpand,
  LuZoomIn,
  LuZoomOut,
} from "react-icons/lu";
import Typography from "../../base-components/Typography";

const Pdf = (props) => {
  const { url, height, width, title } = props;

  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const { GoToNextPage, GoToPreviousPage, CurrentPageLabel } =
    pageNavigationPluginInstance;
  const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
  const { EnterFullScreen } = fullScreenPluginInstance;

  return (
    <div>
      {/* Customize PDF */}
      <div className="flex items-center justify-between">
        <div className="flex space-x-2">
          {title && (
            <Typography variant="Title" size="md">
              {title}
            </Typography>
          )}
          <CurrentPageLabel>
            {(props) => (
              <Typography variant="Title" size="md">{`(${
                props.currentPage + 1
              }/${props.numberOfPages})`}</Typography>
            )}
          </CurrentPageLabel>
        </div>
        <div className="flex items-center space-x-4 max-[500px]:hidden">
          <ZoomOut>
            {(props) => (
              <IconButton variant="softPrimary" onClick={props.onClick}>
                <LuZoomOut />
              </IconButton>
            )}
          </ZoomOut>
          <CurrentScale>
            {(props) => (
              <Typography color="text-font">{`${Math.round(
                props.scale * 100
              )}%`}</Typography>
            )}
          </CurrentScale>
          <ZoomIn>
            {(props) => (
              <IconButton variant="softPrimary" onClick={props.onClick}>
                <LuZoomIn />
              </IconButton>
            )}
          </ZoomIn>
        </div>
        <div className="flex space-x-2">
          <div className="max-[500px]:hidden">
            <EnterFullScreen>
              {(props) => (
                <IconButton variant="softPrimary" onClick={props.onClick}>
                  <LuExpand size={20} />
                </IconButton>
              )}
            </EnterFullScreen>
          </div>
          <GoToPreviousPage>
            {(props) => (
              <IconButton
                as="a"
                variant="outlinePrimary"
                disabled={props.isDisabled}
                onClick={props.onClick}
              >
                <LuChevronLeft />
              </IconButton>
            )}
          </GoToPreviousPage>
          <GoToNextPage>
            {(props) => (
              <IconButton
                as="a"
                variant="outlinePrimary"
                disabled={props.isDisabled}
                onClick={props.onClick}
              >
                <LuChevronRight />
              </IconButton>
            )}
          </GoToNextPage>
        </div>
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <div
          style={{
            height: height,
            width: width,
          }}
          className="border rounded-md"
        >
          {url && (
            <Viewer
              fileUrl={url}
              plugins={[
                pageNavigationPluginInstance,
                zoomPluginInstance,
                fullScreenPluginInstance,
              ]}
            />
          )}
        </div>
      </Worker>
    </div>
  );
};

export default Pdf;
