import React from "react";
import MySavings from "./savings/index";
import RecommendedProducts from "./products";
import Info from "./Info";

const HomePage = () => {
  return (
    <div className="p-4">
      <MySavings />
      <RecommendedProducts />
      <Info />
    </div>
  );
};

export default HomePage;
