import { useState } from "react";
import { X } from "lucide-react";

export default function AppDownloadPopup() {
    const [isOpen, setIsOpen] = useState(true);

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-2xl shadow-xl w-[600px] text-center relative">
                    <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    >
                        <X size={24} />
                    </button>
                    <div className="flex justify-center" style={{ display: "flex", gap: 20 }}>
                        <img
                            src="/ff.png"
                            style={{ height: window.innerWidth <= 640 ? 350 : 500, objectFit: "cover" }}
                            alt="App Preview"
                        />
                        <img
                            src="/image_2.png"
                            style={{ height: window.innerWidth <= 640 ? 350 : 500, objectFit: "cover" }}
                            alt="App Preview"
                        />
                    </div>
                    <h2 className="text-2xl font-semibold mt-4">Хөрөнгө оруулалтаа өөрөө удирдая!!!</h2>
                    <h4 className="text font-semibold mt-4">Апп татах</h4>
                    <div className="flex justify-center gap-6 mt-6">
                        <a href="https://play.google.com/store/apps" target="_blank">
                            <img src="/playstore.png" alt="Google Play" style={{ height: 60 }} />
                        </a>
                        <a href="https://apps.apple.com/app" target="_blank">
                            <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" style={{ height: 60 }} alt="App Store" />
                        </a>
                    </div>
                </div>
            </div>
        )
    );
}
